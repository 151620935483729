<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useAuthStore } from '@/core/store/AuthStore'
import { useCustomerStore } from '@/core/store/CustomerStore';
import '@/core/assets/primevue/neuraxis.css';


const getQueryParam = key => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(key);
}

const token = ref(false)
const user_email = ref("")
const user_firstname = ref("")
const user_lastname = ref("")
const user_password = ref("")
const user_password_repeat = ref("")
const password_too_weak = ref(false)

const keep_logged_in = ref(true);
const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;


onMounted(() =>{
    console.log('mounted')
    const tkn = getQueryParam('token');
    token.value = tkn;
    if(token.value){
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/uniska/invite-token', {token: token.value})
        .then(response => {
            if(response.data.status == 'success'){
                user_email.value = response.data.user_email
            }
        })
    }
})

const register = () => {
    if(user_password.value != user_password_repeat.value || user_password.value.length <= 8 || user_password.value.match(/[a-z]/g) == null || user_password.value.match(/[A-Z]/g) == null || user_password.value.match(/[0-9]/g) == null || user_password.value.match(/[^a-zA-Z\d]/g) == null){
        password_too_weak.value = true;
        return;
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/uniska/register', {token: token.value, user_email: user_email.value, user_firstname: user_firstname.value, user_lastname: user_lastname.value, user_password: user_password.value})
    .then(response => {
        if(response.data.status == 'success'){
            setTimeout(() => {
                window.location.href = '/'
            }, 3000)
        }
    })
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <div class="flex justify-content-center mt-6">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-4" >
            <div class="text-center mb-5">
                <div class="text-900 text-3xl font-medium mb-3">Uniska AG</div>
                <span v-if="user_email" class="text-600 font-medium line-height-3">Sie wurden in die <span class="text-blue-500">Uniska AG</span> Snappy-Instanz eingeladen!</span>
                <span v-if="!user_email" class="text-600 font-medium line-height-3 text-red-500">Dieser Einladungslink ist abgelaufen oder nicht mehr gültig!</span>
            </div>

            <div v-if="user_email">
                <label for="email" class="block text-900 font-medium mt-2">Email</label>
                <InputText disabled id="email" v-model="user_email" type="text" class="w-full mt-3" />

                <label for="firstname" class="block text-900 font-medium mt-2">Vornamen</label>
                <InputText id="firstname" v-model="user_firstname" type="text" class="w-full mt-3" />

                <label for="lastname" class="block text-900 font-medium mt-2">Nachnamen</label>
                <InputText id="lastname" v-model="user_lastname" type="text" class="w-full mt-3" />

                <label for="password" class="block text-900 font-medium mt-2">Passwort</label>
                <InputText id="password" v-model="user_password" type="password" class="w-full mt-3" />
                <small v-if="password_too_weak" class="text-red-500">Passwort zu schwach</small>

                <label for="password_repeat" class="block text-900 font-medium mt-2">Passwort wiederholen</label>
                <InputText id="password_repeat" v-model="user_password_repeat" type="password" class="w-full mt-3" />

                <Button :disabled="user_email && user_firstname && user_lastname && user_password && user_password_repeat && user_password == user_password_repeat ? false : true" @click="register()" label="Registrieren" icon="pi pi-check" class="w-full mt-4"></Button>
            </div>
        </div>
    </div>
</template>