<script setup>
import { ref, onMounted, watch } from 'vue'
import axios from "axios"
import { FilterMatchMode } from 'primevue/api'

const loader = ref(false)
const login = ref(false)
const mobile = ref('')
const activeTabIndex = ref(0)
const filter_products = ref({'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })

const edit_job_slider = ref(false)
const view_application_slider = ref(false)
const pdf_viewer = ref(false)

onMounted(() =>{
    
})

const admin_login = () => {
    console.log(mobile.value)
    if (mobile.value == '079-999-9999') {
        login.value = true
    }
}

const edit_job = (jobdata) => {
    console.log(jobdata)
    job.value = jobdata
    edit_job_slider.value = true
}

const view_application = (event) => {
    console.log(event)
    application.value = event.data
    view_application_slider.value = true
}

const pdf_url = ref('')
const open_pdf = (url) => {
    pdf_viewer.value = true
    pdf_url.value = url
}

const open_url = (url) => {
    window.open(url, '_blank')
} 

const swiss_date_time = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

const edit_users = ref([])
const on_row_user_save = (event) => {
    let { newData, index } = event
    users.value[index] = newData
}
const users = [
    {
        "name": "Bryan Wirth",
        "mobile": "079-108-3541"
    },
    {
        "name": "Abdou Thiam",
        "mobile": "076-602-8041"
    }
]

const job = ref({})
const jobs = ref([
    {
        "job_id": "12345",
        "job_title": "Privatkundenberater",
        "postal_code": "5000",
        "city": "Aarau",
        "job_type": "Festanstellung",
        "job_capacity": "80% - 100%",
        "job_description": [
            {"type": "3-dots", "description": "Sie beraten und betreuen unsere Privatkunden in allen Finanzfragen"},
            {"type": "3-dots", "description": "Sie erkennen die Bedürfnisse unserer Kunden und bieten ihnen massgeschneiderte Lösungen an"},
            {"type": "3-dots", "description": "Sie akquirieren Neukunden und bauen Ihr eigenes Kundenportefeuille auf"},
            {"type": "2-dots", "description": "Sie pflegen und erweitern Ihr Netzwerk"}
        ],
        "job_requirements": [
            {"type": "education", "description": "Sie haben eine abgeschlossene Bankausbildung und bringen Erfahrung im Verkauf von Finanzdienstleistungen mit"},
            {"type": "expirience", "description": "Sie sind eine kommunikative und offene Persönlichkeit"},
            {"type": "language", "description": "Sie sind ein Teamplayer und arbeiten gerne selbstständig"},
            {"type": "3-dots", "description": "Sie sind flexibel und belastbar"},
            {"type": "3-dots", "description": "Sie sind motiviert und haben Freude am Verkauf"}
        ],
        "job_contact": {
            "name": "Herr Peter Meier",
            "email": "peter.meier@test.com"
        },
        "job_url": "https://www.test.com",
        "job_runtime_start": "2024-10-10T10:00:00",
        "job_runtime_end": "2024-12-10T10:00:00",
        "job_status": "active"
    },
    {
        "job_id": "12346",
        "job_title": "Kundenberater",
        "postal_code": "8000",
        "city": "Zürich",
        "job_type": "Temporär",
        "job_capacity": "100%",
        "job_description": [
            {"type": "3-dots", "description": "Sie beraten und betreuen unsere Kunden in allen Finanzfragen"},
            {"type": "3-dots", "description": "Sie erkennen die Bedürfnisse unserer Kunden und bieten ihnen massgeschneiderte Lösungen an"},
            {"type": "3-dots", "description": "Sie akquirieren Neukunden und bauen Ihr eigenes Kundenportefeuille auf"},
            {"type": "2-dots", "description": "Sie pflegen und erweitern Ihr Netzwerk"}
        ],
        "job_requirements": [
            {"type": "education", "description": "Sie haben eine abgeschlossene Bankausbildung und bringen Erfahrung im Verkauf von Finanzdienstleistungen mit"},
            {"type": "expirience", "description": "Sie sind eine kommunikative und offene Persönlichkeit"},
            {"type": "language", "description": "Sie sind ein Teamplayer und arbeiten gerne selbstständig"},
            {"type": "3-dots", "description": "Sie sind flexibel und belastbar"},
            {"type": "3-dots", "description": "Sie sind motiviert und haben Freude am Verkauf"}
        ],
        "job_contact": {
            "name": "Frau Sabine Müller",
            "email": "test@test.com"
        },
        "job_url": "https://www.test.com",
        "job_runtime_start": "2024-10-10T10:00:00",
        "job_runtime_end": "2024-12-10T10:00:00",
        "job_status": "inactive"
    }
])

const job_types = [
    { label: 'Festanstellung', value: 'Festanstellung' },
    { label: 'Temporär', value: 'Temporär' },
    { label: 'Teilzeit', value: 'Teilzeit' },
    { label: 'Vollzeit', value: 'Vollzeit' }
]

const job_description_types = [
    { label: '3-Punkte', value: '3-dots' },
    { label: '2-Punkte', value: '2-dots' },
    { label: '1-Punkt', value: '1-dot' },
    { label: 'Sprache', value: 'language' },
    { label: 'Ausbildung', value: 'education' },
    { label: 'Erfahrung', value: 'expirience' }
]

const get_job_description_type = (type) => {
    let job_description_type = job_description_types.find(x => x.value == type)
    return job_description_type.label
}

const application = ref({})
const applications = ref([
    {
        "application_id": "12345",
        "job_id": "12345",
        "name": "Bryan Wirth",
        "mobile": "079-108-3541",
        "email": "wirth@impact-solutions.ch",
        "status": "NEU",
        "created_at": "2024-10-10T10:00:00",
        "application_file": "https://resumesbot.com/wp-content/uploads/2019/agriculture-cover-letter-example.pdf"
    },
])

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<style>
.mb_url {
    color: #144B3C;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}
.mb_green {
    background-color: #144B3C;
    color: #FFF;
    border-color: #FFF;
}

.mb_green_border {
    border-color: #144B3C;
}

.mb_pink {
    background-color: #E34055;
    color: #FFF;
    border-color: #E34055;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #144B3C;
    border-color: #144B3C;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #144B3C;
}
</style>

<template>
    <ProgressSpinner v-if="loader == true" style="position: fixed; z-index: 999; overflow: show; margin: auto; top: 0; left: 0; bottom: 0; right: 0; width: 50px; height: 50px;" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" aria-label="Loading..." />
    <div class="flex justify-content-center mt-6">
        <div class="surface-card p-4 shadow-3 border-round" style="width: 1200px" >
            <div class="flex justify-content-between">
                <img src="../assets/images/snappy_onyx_small.png" width="200" />
            </div>
            <div class="flex justify-content-between">
                <h1 class="font-bold pl-6 pt-1">Jobs</h1>
            </div>
            <div v-if="!login" class="flex justify-content-center mt-6">
                <div style="width: 500px" class="mb-10">
                    <FloatLabel>
                        <InputMask id="mobile" v-model="mobile" mask="079-999-9999" placeholder="079-123-4567" class="w-16rem" />
                        <label for="mobile">Handy-Nr</label>
                    </FloatLabel>
                    <Button label="Login" icon="pi pi-sign-in" @click="admin_login()" class="mt-2" />
                </div>
            </div>


            <div v-if="login" class="mb-5">
                <div v-if="!loader">
                    <div class="mt-3">
                        <TabView class="col-12" ref="tabview4" v-model:activeIndex="activeTabIndex">
                            <TabPanel>
                                <template #header>
                                    <i class="pi pi-pen-to-square mr-2"></i>
                                    <span class="mr-3">Bewerbungen</span>
                                </template>
                                <DataTable :value="applications" @row-click="view_application" :rowHover="true" :paginator="true" :rows="20" :rowsPerPageOptions="[20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                                    <Column field="name" header="Name" sortable>
                                        <template #body="slotProps">
                                            <i class="pi pi-pen-to-square mr-2 text-primary"></i><span class="font-bold">{{ slotProps.data.name }}</span>
                                        </template>
                                    </Column>
                                    <Column field="mobile" header="Handy-Nr" sortable>
                                        <template #body="slotProps">
                                            <span>{{ slotProps.data.mobile }}</span>
                                        </template>
                                    </Column>
                                    <Column field="email" header="Email" sortable>
                                        <template #body="slotProps">
                                            <span>{{ slotProps.data.email }}</span>
                                        </template>
                                    </Column>
                                    <Column field="status" header="Status" sortable>
                                        <template #body="slotProps">
                                            <Chip v-if="slotProps.data.status == 'active'" class="bg-green-100 text-black-alpha-90" label="Aktiv" />
                                            <Chip v-if="slotProps.data.status == 'inactive'" class="bg-red-100 text-black-alpha-90" label="Inaktiv" />
                                        </template>
                                    </Column>
                                    <Column field="created_at" header="Erstellt am" sortable>
                                        <template #body="slotProps">
                                            <span>{{ swiss_date_time(slotProps.data.created_at) }}</span>
                                        </template>
                                    </Column>
                                </DataTable>
                            </TabPanel>
                            <TabPanel>
                                <template #header>
                                    <i class="pi pi-briefcase mr-2"></i>
                                    <span class="mr-3">Stellenausschreibungen</span>
                                </template>
                                <DataTable :value="jobs" :rowHover="true" :paginator="true" :rows="20" :rowsPerPageOptions="[20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                                    <Column field="job_title" header="Stelle" sortable>
                                        <template #body="slotProps">
                                            <i class="pi pi-briefcase mr-2 text-primary"></i><span class="font-bold">{{ slotProps.data.job_title }}</span>
                                        </template>
                                    </Column>
                                    <Column field="job_runtime_start" header="Start" sortable>
                                        <template #body="slotProps">
                                            <span>{{ swiss_date_time(slotProps.data.job_runtime_start) }}</span>
                                        </template>
                                    </Column>
                                    <Column field="job_runtime_end" header="Ende" sortable>
                                        <template #body="slotProps">
                                            <span>{{ swiss_date_time(slotProps.data.job_runtime_end) }}</span>
                                        </template>
                                    </Column>
                                    <Column field="job_status" header="Status" sortable>
                                        <template #body="slotProps">
                                            <Chip v-if="slotProps.data.job_status == 'active'" class="bg-green-100 text-black-alpha-90" label="Aktiv" />
                                            <Chip v-if="slotProps.data.job_status == 'inactive'" class="bg-red-100 text-black-alpha-90" label="Inaktiv" />
                                        </template>
                                    </Column>
                                    <Column>
                                        <template #body="slotProps">
                                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-text" v-tooltip.top="'Job bearbeiten'" @click="edit_job(slotProps.data)" />
                                            <Button icon="pi pi-chart-line" class="p-button-rounded p-button-text" v-tooltip.top="'Statistik'" />
                                            <Button icon="pi pi-trash" class="p-button-rounded p-button-text text-red-500" v-tooltip.top="'Job löschen'" />
                                        </template>
                                        <template #header>
                                            <Button icon="pi pi-plus" class="p-button-rounded" v-tooltip.top="'Job hinzufügen'" @click="create_job" />
                                        </template>
                                    </Column>
                                </DataTable>
                            </TabPanel>
                            <TabPanel>
                                <template #header>
                                    <i class="pi pi-lock mr-2"></i>
                                    <span class="mr-3">Benutzer</span>
                                </template>
                                <DataTable v-model:editingRows="edit_users" editMode="row" dataKey="mobile" @row-edit-save="on_row_user_save" :value="users" :rowHover="true" :paginator="true" :rows="20" :rowsPerPageOptions="[20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                                    <Column field="name" header="Name" sortable>
                                        <template #body="slotProps">
                                            <i class="pi pi-user mr-2 text-primary"></i><span class="font-bold">{{ slotProps.data.name }}</span>
                                        </template>
                                        <template #editor="slotProps">
                                            <InputText v-model="slotProps.data.name" class="w-full" />
                                        </template>
                                    </Column>
                                    <Column field="mobile" header="Handy-Nr" sortable>
                                        <template #body="slotProps">
                                            <span>{{ slotProps.data.mobile }}</span>
                                        </template>
                                        <template #editor="slotProps">
                                            <InputText v-model="slotProps.data.mobile" class="w-full" />
                                        </template>
                                    </Column>
                                    <Column :rowEditor="true" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
                                        <template #header="slotProps">
                                            <Button icon="pi pi-plus" class="mr-2 p-button-rounded" v-tooltip.top="'Benutzer hinzufügen'" @click="add_user(slotProps)" />
                                        </template>
                                    </Column>
                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!-------------------------------- Sidebar: Sources ----------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Sidebar header="Job bearbeiten" v-model:visible="edit_job_slider" position="right" style="width: 80%">
        <div class="grid">
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Job Titel
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="job.job_title" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                PLZ
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="job.postal_code" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Ort
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="job.city" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Job Typ
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Dropdown v-model="job.job_type" :options="job_types" optionLabel="label" optionValue="value" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Kapazität
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="job.job_capacity" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Was du bewegst
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <DataTable :value="job.job_description" editMode="cell" @cell-edit-complete="edit_job_description_save" :rowHover="true" :paginator="true" :rows="20" :rowsPerPageOptions="[20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="type" header="Typ" sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{ get_job_description_type(slotProps.data.type) }}</span>
                        </template>
                        <template #editor="slotProps">
                            <Dropdown v-model="slotProps.data.type" :options="job_description_types" optionLabel="label" optionValue="value" class="w-full" />
                        </template>
                    </Column>
                    <Column field="description" header="Beschreibung" sortable>
                        <template #body="slotProps">
                            <span>{{ slotProps.data.description }}</span>
                        </template>
                        <template #editor="slotProps">
                            <InputText v-model="slotProps.data.description" class="w-full" />
                        </template>
                    </Column>
                    <Column style="width: 10%; min-width: 8rem">
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="p-button-rounded" v-tooltip.top="'Beschreibung hinzufügen'" @click="add_job_description(slotProps)" />
                        </template>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-text text-red-500" v-tooltip.top="'Beschreibung löschen'" @click="delete_job_description(slotProps)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Was du mitbringst
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <DataTable :value="job.job_requirements" editMode="cell" @cell-edit-complete="edit_job_requirements_save" :rowHover="true" :paginator="true" :rows="20" :rowsPerPageOptions="[20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="type" header="Typ" sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{ get_job_description_type(slotProps.data.type) }}</span>
                        </template>
                        <template #editor="slotProps">
                            <Dropdown v-model="slotProps.data.type" :options="job_description_types" optionLabel="label" optionValue="value" class="w-full" />
                        </template>
                    </Column>
                    <Column field="description" header="Beschreibung" sortable>
                        <template #body="slotProps">
                            <span>{{ slotProps.data.description }}</span>
                        </template>
                        <template #editor="slotProps">
                            <InputText v-model="slotProps.data.description" class="w-full" />
                        </template>
                    </Column>
                    <Column style="width: 10%; min-width: 8rem">
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="p-button-rounded" v-tooltip.top="'Beschreibung hinzufügen'" @click="add_job_requirements(slotProps)" />
                        </template>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-text text-red-500" v-tooltip.top="'Beschreibung löschen'" @click="delete_job_requirements(slotProps)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Kontaktperson
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="job.job_contact.name" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Email
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="job.job_contact.email" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                URL
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="job.job_url" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Laufzeit (Start/Ende)
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Calendar v-model="job.job_runtime_start" class="w-auto mr-2" showIcon />
                <Calendar v-model="job.job_runtime_end" class="w-auto" showIcon />
            </div>
            <div class="col-10 p-2 pt-4 border-blue-100 border-solid border-bottom-1 m-0">
                <Button label="Job speichern" icon="pi pi-save" class="p-button-success w-auto mr-2" @click="save_job()" />
                <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="edit_job_slider = false" />
            </div>
            <div class="col-2 p-2 pt-4 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <Button label="Job löschen" icon="pi pi-trash" class="p-button-danger w-auto" @click="delete_job()" />
            </div>
        </div>
    </Sidebar>

    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!-------------------------------- Sidebar: Application ------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Sidebar header="Bewerbung" v-model:visible="view_application_slider" position="left" style="width: 50%">
        <div class="grid">
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Bewerbung PDF
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Button label="CV PDF ansehen" icon="pi pi-file-pdf" class="p-button-primary w-auto" @click="open_pdf(application.application_file)" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Name
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="application.name" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Handy-Nr
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="application.mobile" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Email
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="application.email" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Status
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Chip v-if="application.status == 'NEU'" class="bg-green-100 text-black-alpha-90" label="NEU" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Erstellt am
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="application.created_at" class="w-full" />
            </div>
            <div class="col-12 p-2 pt-4 border-blue-100 border-solid border-bottom-1 m-0">
                <div class="surface-card p-4 shadow-3 border-round">
                    <div class="font-bold text-xl flex justify-content-center mt-2">Ist dieser Kontakt interessant?</div>
                    <div class="flex justify-content-center mt-3">
                        <Button label="Ja" icon="pi pi-check" class="p-button-success w-auto mr-2" />
                        <Button label="Unklar" icon="pi pi-question" class="p-button-warning w-auto mr-2" />
                        <Button label="Nein" icon="pi pi-times" class="p-button-danger w-auto" />
                    </div>
                </div>
            </div>
        </div>
    </Sidebar>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!---------------------------- Sidebar: PDF iFrame viewer ----------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Sidebar v-model:visible="pdf_viewer" position="right" style="width: 80%">
        <iframe id="pdf_viewer" :src="pdf_url" width="100%" height="100%" frameborder="0"></iframe>
    </Sidebar>
</template>